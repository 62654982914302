import { template as template_929c43f37c7a4b3c9f945c546c223a17 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_929c43f37c7a4b3c9f945c546c223a17(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
