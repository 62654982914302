import { template as template_321c5102a1f24bedbd3b813d0d7c70f5 } from "@ember/template-compiler";
const FKText = template_321c5102a1f24bedbd3b813d0d7c70f5(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
