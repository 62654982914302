import { template as template_8caf0dd6d306428ab13aae94b48534dc } from "@ember/template-compiler";
const FKLabel = template_8caf0dd6d306428ab13aae94b48534dc(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
